* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

@media (max-width: 1200px) {
  #mobile {
    display: block;
  }

  #desktop {
    display: none;
  }
}

@media (min-width: 1200px) {
  #mobile {
    display: none;
  }

  #desktop {
    display: block;
  }
}

body {
  font-size: 25px;
  font-weight: 200;
}

#root {
  overflow: hidden;
}

button:disabled {
  opacity: 0.3;
}

strong {
  color: #95c11f;
  font-weight: 500;
}

a {
  display: flex;
  font-size: 13px;
  margin-bottom: 10px;
  align-items: center;
  text-decoration: none;
  border-bottom: solid 1px transparent;
}

a span {
  color: #2b475e;
  text-decoration: none;
  border-bottom: solid 1px transparent;
  font-family: 'IBM Plex Mono', monospace;
}

button {
  font-family: 'IBM Plex Mono', monospace;
}

a:hover span {
  border-bottom: solid 1px #2b475e;
}

section li {
  list-style-type: '—   ';
}

nav li {
  margin-bottom: 10px;
  list-style-type: none;
  font-weight: 400;
}

input,
textarea {
  border: 1px solid #2b475e;
}

input:focus,
textarea:focus {
  outline: 1px solid rgba(43, 71, 94, 0.25);
}

::placeholder {
  color: rgba(43, 71, 94, 0.5);
}

#introduction::before {
  top: 0;
  left: 0;
  content: '';
  z-index: -1;
  width: 100%;
  height: 200%;
  position: absolute;
  background: linear-gradient(to bottom, #cad9ec, transparent);
}

footer {
  overflow: hidden;
}

footer a.mentions {
  display: inline;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  color: #2b475e;
  border-bottom: solid 1px #2b475e;
}

footer::before {
  left: 0;
  top: -100px;
  z-index: -1;
  content: '';
  width: 100%;
  height: 100%;
  margin-top: 100px;
  position: absolute;
  background: linear-gradient(to bottom, transparent, #cad9ec);
}

.trame {
  width: calc(100vw / 1.4);
  height: calc(100vw / 1.4);
  pointer-events: none;
  overflow: visible;
}

@media (max-width: 950px) {
  .trame {
    width: calc(100vw);
    height: calc(100vw);
  }
}

@media (max-width: 700px) {
  .trame {
    width: calc(125vw);
    height: calc(125vw);
  }
}

@media (max-width: 600px) {
  .trame {
    width: calc(175vw);
    height: calc(175vw);
  }
}

#mentions a.back-home {
  display: inline;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  color: #2b475e;
  border-bottom: solid 1px #2b475e;
  background-color: red;
}
